<template>
  <div data-cy="payments-container" class="container">
    <h2 :data-cy="dataCyName(['payments', 'title'])" class="mb-0">
      {{ component.title }}
    </h2>
    <div data-cy="payment-methods" class="d-flex flex-row flex-wrap mb-28">
      <strapi-image
        v-for="paymentMethod in component.paymentMethods"
        :key="paymentMethod.id"
        :force-height="45"
        :image="paymentMethod.image"
        :alt="paymentMethod.title"
        class="col-12 col-md-6 col-lg-3 mt-8 text-center"
      />
    </div>
  </div>
</template>

<script lang="ts">
import StrapiImage from './StrapiImage.vue';
import type { StrapiPaymentMethod } from '~/apollo/types/types';
import dataCyName from '~/helpers/cypress';

export default defineComponent({
  name: 'StrapiProductPayments',
  components: { StrapiImage },
  props: {
    component: {
      type: Object as PropType<StrapiPaymentMethod>,
      required: true,
    },
  },
  setup(props) {
    return {
      dataCyName,
    };
  },
});
</script>
